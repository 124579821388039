<template>
  <div class="row">
    <div class="col-md-8 col-sm-12">
      <div class="accordion">
        <div class="card">
          <div id="headingOne">
            <div class="card-header">
              <div class="card-title my-2">Transaction Information</div>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <div>
                <div class="info-label">Transaction Date</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">
                  {{
                    orderReceipt.transactionDate
                      | formatDateTime("MM/DD/YYYY hh:mm A")
                  }}
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div class="info-label">Transaction Type</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ orderReceipt.transactionType }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div class="info-label">Customer Name</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ orderReceipt.customerName }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div class="info-label">Customer Address</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ orderReceipt.customerAddress }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div class="info-label">Customer Email</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ orderReceipt.customerEmail }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div class="info-label">Confirmation Number</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ orderReceipt.orderNumber }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="orderReceipt.reservations">
        <div
          class="accordion"
          v-for="(reservation, index) in orderReceipt.reservations"
          :key="reservation.id"
        >
          <div class="card">
            <div id="headingTwo">
              <div class="card-header">
                <div class="card-title my-2">
                  Reservation {{ index + 1 }}: {{ reservation.parkName }}, Spot
                  {{ reservation.site }}
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="d-flex">
                <div>
                  <div class="info-label">Reservation Number</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.reservationNumber }}
                  </div>
                </div>
              </div>
              <hr />
              <div class="d-flex">
                <div>
                  <div class="info-label">Check In</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">{{ reservation.checkIn }}</div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Check Out</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">{{ reservation.checkOut }}</div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Number of Nights</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.numberOfNights || numberOfNights }}
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Occupants</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.numberOfOccupants }}
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Vehicles</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.numberOfVehicles }}
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Primary Occupant</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.primaryOccupantName }}
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Primary Occupant Address</div>
                </div>
                <div class="ml-auto">
                  <div
                    class="info-label"
                    v-html="reservation.primaryOccupantAddress"
                  ></div>
                </div>
              </div>
              <hr />
              <div class="d-flex">
                <div>
                  <div class="info-label">Park Name</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">{{ reservation.parkName }}</div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Park Address</div>
                </div>
                <div class="ml-auto">
                  <div
                    class="info-label"
                    v-html="reservation.parkAddress"
                  ></div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Park Phone</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.parkPhone | formatPhoneNumber }}
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Loop</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">{{ reservation.area }}</div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Spot</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">{{ reservation.site }}</div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Spot Type(s)</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">{{ reservation.siteTypes }}</div>
                </div>
              </div>
              <hr />
              <div class="d-flex">
                <div>
                  <div class="info-label">Park Use Fee</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.reservationFeeAmount }}
                  </div>
                </div>
              </div>
              <div
                class="d-flex"
                v-if="
                  reservation.addOnFeeAmount != null &&
                    reservation.addOnFeeAmount != '$0.00'
                "
              >
                <div>
                  <div class="info-label">Firewood Bundle(s)</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.addOnFeeAmount }}
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">
                    Transaction Fee
                    <i>(non-refundable)</i>
                  </div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.transactionFeeAmount }}
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">
                    Convenience Fee
                    <i>(non-refundable)</i>
                  </div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    {{ reservation.convenienceFeeAmount }}
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">
                    <b>Amount Paid</b>
                  </div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">
                    <b>{{ reservation.amountPaid }}</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card" v-else>
        <div id="headingTwo">
          <div class="card-header">
            <div class="card-title my-2">
              {{ orderReceipt.parkName }}, Spot
              {{ orderReceipt.site }}
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="d-flex">
            <div>
              <div class="info-label">Reservation Number</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.reservationNumber }}</div>
            </div>
          </div>
          <hr />
          <div class="d-flex">
            <div>
              <div class="info-label">Check In</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.checkIn }}</div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Check Out</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.checkOut }}</div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Number of Nights</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.numberOfNights || numberOfNights }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Occupants</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.numberOfOccupants }}</div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Vehicles</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.numberOfVehicles }}</div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Primary Occupant</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.primaryOccupantName }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Primary Occupant Address</div>
            </div>
            <div class="ml-auto">
              <div
                class="info-label"
                v-html="orderReceipt.primaryOccupantAddress"
              ></div>
            </div>
          </div>
          <hr />
          <div class="d-flex">
            <div>
              <div class="info-label">Park Name</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.parkName }}</div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Park Address</div>
            </div>
            <div class="ml-auto">
              <div class="info-label" v-html="orderReceipt.parkAddress"></div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Park Phone</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.parkPhone | formatPhoneNumber }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Loop</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.area }}</div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Spot</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.site }}</div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Spot Type(s)</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.siteTypes }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4 col-sm-12">
      <div
        class="card"
        v-if="
          (orderReceipt.transactionAmountPaid &&
            orderReceipt.transactionAmountPaid != '') ||
            orderReceipt.paymentType == ''
        "
      >
        <div class="card-header">
          <div class="card-title my-2">Payment Information</div>
        </div>
        <div class="card-body">
          <div v-if="orderReceipt.paymentType !== ''">
            <div class="d-flex">
              <div>
                <div class="info-label">Payment Type</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ orderReceipt.paymentType }}</div>
              </div>
            </div>
            <div class="d-flex" v-if="orderReceipt.paymentType !== 'Cash'">
              <div>
                <div class="info-label">
                  {{ orderReceipt.cardHolderNameLabel }}
                </div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ orderReceipt.cardHolderName }}</div>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div class="info-label">{{ orderReceipt.lastFourLabel }}</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ orderReceipt.lastFour }}</div>
              </div>
            </div>
            <hr />
          </div>
          <div class="d-flex" v-if="orderReceipt.previousParkUseFeePaid">
            <div>
              <div class="info-label">Park Use Fee - Paid</div>
            </div>
            <div class="ml-auto">
              <div class="info-label green-label">
                {{ orderReceipt.previousParkUseFeePaid }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Park Use Fee</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.totalParkUseFee }}</div>
            </div>
          </div>
          <div
            class="d-flex"
            v-if="
              orderReceipt.discountApplied != null &&
                orderReceipt.discountApplied != '' &&
                orderReceipt.discountApplied != '(0.00)' &&
                orderReceipt.discountApplied != '-0.00'
            "
          >
            <div>
              <div class="info-label">Discount Applied</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.discountApplied }}</div>
            </div>
          </div>
          <div
            class="d-flex"
            v-if="
              orderReceipt.totalAddOnFeeAmount != null &&
                orderReceipt.totalAddOnFeeAmount != '$0.00'
            "
          >
            <div>
              <div class="info-label">Firewood Bundle(s)</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalAddOnFeeAmount }}
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            v-if="orderReceipt.totalModificationFeeAmount != null"
          >
            <div>
              <div class="info-label">Modification Fee</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalModificationFeeAmount }}
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            v-if="
              orderReceipt.totalAddOnFeeAmount != null &&
                orderReceipt.totalAddOnFeeAmount > 0
            "
          >
            <div>
              <div class="info-label">Firewood Bundle(s)</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalAddOnFeeAmount }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="mb-2">
              <div class="info-label mb-0">
                Transaction Fee
              </div>
              <div class="mt-n1">
                <sub>(Non Refundable)</sub>
              </div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalTransactionFeeAmount }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="mb-2">
              <div class="info-label mb-0">
                Convenience Fee
              </div>
              <div class="mt-n1">
                <sub>(Non Refundable)</sub>
              </div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalConvenienceFeeAmount }}
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            v-if="
              orderReceipt.previousAmountRefunded &&
                orderReceipt.previousAmountRefunded != '$0.00'
            "
          >
            <div>
              <div class="info-label">Previous Amount Refunded</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.previousAmountRefunded }}
              </div>
            </div>
          </div>
          <div v-if="orderReceipt.transactionAmountPaid">
            <hr />
            <div class="d-flex">
              <div>
                <div class="info-label">Amount Paid</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">
                  {{ orderReceipt.transactionAmountPaid }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="card"
        v-if="
          orderReceipt.transactionAmountPaid == null ||
            ((orderReceipt.transactionAmountPaid == '$0.00' ||
              orderReceipt.transactionAmountPaid == '') &&
              orderReceipt.transactionAmountRefunded !== '$0.00' &&
              orderReceipt.transactionAmountRefunded !== null)
        "
      >
        <div class="card-header">
          <div class="card-title my-2">Refund Information</div>
        </div>
        <div class="card-body">
          <b>Refund Applied To:</b>
          <br />
          <br />
          <div v-for="(item, index) in orderReceipt.refundItems" :key="index">
            <div class="d-flex">
              <div>
                <div class="info-label">Payment Type</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ item.paymentType }}</div>
              </div>
            </div>
            <div v-if="item.paymentType === 'Credit Card'">
              <div class="d-flex">
                <div>
                  <div class="info-label">Cardholder Name</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">{{ item.cardHolderName }}</div>
                </div>
              </div>
              <div class="d-flex">
                <div>
                  <div class="info-label">Credit Card Number</div>
                </div>
                <div class="ml-auto">
                  <div class="info-label">{{ item.lastFour }}</div>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div class="info-label">Refund Amount</div>
              </div>
              <div class="ml-auto">
                <div class="info-label">{{ item.amountRefunded }}</div>
              </div>
            </div>
            <hr />
          </div>
          <div class="d-flex" v-if="orderReceipt.previousParkUseFeePaid">
            <div>
              <div class="info-label">Park Use Fee - Paid</div>
            </div>
            <div class="ml-auto">
              <div class="info-label green-label">
                {{ orderReceipt.previousParkUseFeePaid }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div>
              <div class="info-label">Park Use Fee</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">{{ orderReceipt.totalParkUseFee }}</div>
            </div>
          </div>
          <div class="d-flex" v-if="orderReceipt.totalCancelationFeeAmount">
            <div>
              <div class="info-label">Cancellation Fee</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalCancelationFeeAmount }}
              </div>
            </div>
          </div>
          <div
            class="d-flex"
            v-if="orderReceipt.totalModificationFeeAmount != null"
          >
            <div>
              <div class="info-label">Modification Fee</div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalModificationFeeAmount }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="mb-2">
              <div class="info-label mb-0">
                Transaction Fee
              </div>
              <div class="mt-n1">
                <sub>(Non Refundable)</sub>
              </div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalTransactionFeeAmount }}
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="mb-2">
              <div class="info-label mb-0">
                Convenience Fee
              </div>
              <div class="mt-n1">
                <sub>(Non Refundable)</sub>
              </div>
            </div>
            <div class="ml-auto">
              <div class="info-label">
                {{ orderReceipt.totalConvenienceFeeAmount }}
              </div>
            </div>
          </div>
          <hr />
          <div class="d-flex">
            <div>
              <div class="info-label">Amount Refunded</div>
            </div>
            <div class="ml-auto">
              <div class="info-label green-label">
                {{ orderReceipt.totalAmountRefunded }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "AdminReservationReceipt",
  props: {
    orderReceipt: Object
  },
  computed: {
    numberOfNights() {
      return isNaN(
        moment(this.orderReceipt.checkOut, "MM/DD/YYYY hh:mm a").diff(
          moment(this.orderReceipt.checkIn, "MM/DD/YYYY hh:mm a"),
          "days"
        )
      )
        ? 0
        : moment(this.orderReceipt.checkOut, "MM/DD/YYYY hh:mm a").diff(
            moment(this.orderReceipt.checkIn, "MM/DD/YYYY hh:mm a"),
            "days"
          ) + 1;
    }
  }
};
</script>

<style scoped>
@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
  * {
    overflow: visible !important;
  }
  .page {
    page-break-after: always;
  }
}
.info-label {
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  font-weight: 400;
  line-height: 1.2;
  color: #000;
}
.green-label {
  color: #388038 !important;
}
</style>
